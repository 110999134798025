.payment-form {
    min-width: 300px;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    background-color: #f9fafb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #32325d;
}

.card-element {
    padding: 10px 12px;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
}

.submit-button {
    width: 100%;
    padding: 12px;
    background-color: #6772e5;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #5469d4;
}

.error-message {
    color: #fa755a;
    margin-top: 16px;
}

.success-message {
    color: #4caf50;
    margin-top: 16px;
}